import React from "react"
import Hero from "../../components/hero"
import SiteMetaData from "../../components/sitemeta"
import teamStyles from "../../components/teams.module.scss"
import TeamData from "../../components/team-data.js"
import Layout from "../../components/layout-teams"

const BachataTeam = ({data}) => (
  <Layout data={data} media="images">
    <div style={({"backgroundColor":"black"})}>
      <SiteMetaData 
        title="Bachata Teams | RF Dance"
        description="OC's Homegrown latin dance classes with Rhythmic Fusion. $15 for a single class, or $65 for a 5 class bundle." 
        pathname="teams/bachata"/>
      <Hero 
        titleRed="Bachata"
        titleWhite="Team" />

      <div className={`${teamStyles.auditions} content-block`}>
        <div className={`${teamStyles.container} container`}>
          {/* <TeamData
              name="Beginner &amp; Beginner-Intermediate Auditions"
              subheading=""
              ctaHeader="Next auditions for these two teams:"
              date="March 18, 2019, 9PM"
              comment="This date has reached capacity. Please sign up for the next date."
              link2="https://www.eventbrite.com/e/rf-bachata-team-auditions-beginner-beginner-intermediate-registration-57065183500"
              date2="March 19, 2019, 9PM - 10:30 PM"> 
            <p>RF’s Beginner Bachata Performance Team is the best place to start for the true beginner. <strong>No experience required.</strong> Commit to weekly evening rehearsals and enjoy expediting your bachata learning experience.</p>
            <p>Have a couple of years of Bachata experience under your belt? RF’s Beginner-Intermediate Performance Team is perfect for those who have the basics down and want to experience the next level.</p>
          </TeamData> */}

          <TeamData
              name="Beginner &amp; Beginner-Intermediate Auditions"
              subheading=""
              // ctaHeader="Stay tuned for the next auditions for this team."
              link="https://www.eventbrite.com/e/91485937903"
              date="March 26, 2020"
              link2="https://www.eventbrite.com/e/91728712047"
              date2="March 17, 2020"
              > 
            <p>RF’s Beginner Bachata Performance Team is the best place to start for the true beginner. <strong>No experience required.</strong> Commit to weekly evening rehearsals and enjoy expediting your bachata learning experience.</p>
            <p>Have a couple of years of Bachata experience under your belt? RF’s Beginner-Intermediate Performance Team is perfect for those who have the basics down and want to experience the next level.</p>
          </TeamData>

          <TeamData
            name="Intermediate &amp; Intermediate-Advanced Auditions"
            subheading="2+ years experience"
            link="https://www.eventbrite.com/e/91487227761"
            // ctaHeader="Stay tuned for the next auditions for this team."
            date="March 23, 2020"
            date2="March 24, 2020"
            link2="https://www.eventbrite.com/e/91729428189"
            // makeup="makeup"
            >
            <p>Join RF’s Intermediate Performance Team! This team is geared towards students with at least 2 years of performance and/or social dancing experience who are looking to improve their technique on the stage.</p>

            <p>RF’s Intermediate-Advanced Performance Team is the most advanced Bachata team within RF’s Dance Company. This team requires at least 5 years of experience, preferably with some performance exposure, and is set in a challenging, fast-paced environment.</p>
          </TeamData>
        </div>
      </div>
    </div>
  </Layout>
)

export default BachataTeam